import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Bar from '../components/Bar'
import Main from '../components/Main'
import MatchGrid from '../components/MatchGrid'

export default function Competition({ data: { competition } }) {
  return (
    <Layout>
      <SEO
        title={competition.name}
        description={`Endspiele im ${competition.name}`}
      />
      <Bar color="--primary-800">
        <h2>{competition.name}</h2>
      </Bar>
      <Main>
        <MatchGrid competition={competition} />
      </Main>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    competition(slug: { eq: $slug }) {
      id
      name
      matches: childrenMatch {
        id
        season
        result
        winner {
          team
          country
        }
        runnerUp {
          team
          country
        }
      }
    }
  }
`
