import React from 'react'
import styled from 'styled-components'

import { Variables } from '../styles/GlobalStyles'
import MatchLink from './MatchLink'

const MatchGridStyles = styled.div`
  ${Variables}

  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;

  @media (min-width: 480px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 767px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .item {
    border: 1px solid var(--primary-900);

    min-height: 100px;
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0.5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
    color: var(--primary-900);
    text-decoration: none;

    &:hover,
    &:focus {
      background: var(--primary-900);
      color: var(--text-on-color);
      text-decoration: none;
    }
  }
`

export default function MatchGrid({ competition }) {
  return (
    <MatchGridStyles>
      {competition.matches.map(match => (
        <div className="item" key={match.id}>
          <MatchLink competition={competition} match={match}>
            {match.season}
            <br />
            {match.winner.team} – {match.runnerUp.team}
            <br />
            {match.result}
          </MatchLink>
        </div>
      ))}
    </MatchGridStyles>
  )
}
