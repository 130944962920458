import React from 'react'
import { Link } from 'gatsby'
import slugify from '@sindresorhus/slugify'

export default function MatchLink({ competition, match, children }) {
  return (
    <Link to={`/${slugify(competition.name)}/${slugify(match.season)}`}>
      {children}
    </Link>
  )
}
